// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-thought-post-js": () => import("../src/templates/thought-post.js" /* webpackChunkName: "component---src-templates-thought-post-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-projects-js": () => import("../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-realestate-investing-js": () => import("../src/pages/realestate-investing.js" /* webpackChunkName: "component---src-pages-realestate-investing-js" */),
  "component---src-pages-resume-js": () => import("../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-thoughts-js": () => import("../src/pages/thoughts.js" /* webpackChunkName: "component---src-pages-thoughts-js" */)
}

